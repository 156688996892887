@import url(https://fonts.googleapis.com/css?family=Muli);
@import url(https://fonts.googleapis.com/css?family=Luckiest+Guy);
@import url(https://fonts.googleapis.com/css?family=Bangers);
body {
  background-color: #363636;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Muli', sans-serif;
  color: #ffffff; }

